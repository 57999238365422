// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-exhibition-js": () => import("./../../../src/pages/exhibition.js" /* webpackChunkName: "component---src-pages-exhibition-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-sponsors-js": () => import("./../../../src/pages/sponsors.js" /* webpackChunkName: "component---src-pages-sponsors-js" */),
  "component---src-pages-writings-on-global-cows-js": () => import("./../../../src/pages/writings-on-global-cows.js" /* webpackChunkName: "component---src-pages-writings-on-global-cows-js" */)
}

